<template>
    <div class="box">
        <div class="nav_top">
            <div class="nav_top_cont">
                <div>深圳市花仙科技有限公司</div>
                <!--<div>
                <span @click="logon()">免费注册</span>
                <span @click="Sign()">会员登录</span>
            </div> -->
            </div>
        </div>
        <div class="nav_logo">
            <div class="nav_logo_cont">
                <div class="div_logo">
                    <img src="@/assets/logo.png" />
                </div>

                <el-menu
                    :default-active="activeIndex"
                    text-color="#333"
                    active-text-color="#E36012"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                    router
                >
                    <el-menu-item index="/Home">首页</el-menu-item>
                    <el-submenu index="2">
                        <template slot="title">花仙运车服务</template>
                        <!-- <el-menu-item index="">商品车托运</el-menu-item> -->
                        <!-- <el-menu-item index="">单台配送</el-menu-item> -->
                        <el-menu-item index="/Flow">托运流程</el-menu-item>
                        <el-menu-item index="/Subject"
                            >专业运输合同</el-menu-item
                        >
                    </el-submenu>
                    <!-- <el-menu-item index="">立即获取报价</el-menu-item> -->
                    <el-menu-item index="/Shipment">托运线路</el-menu-item>
                    <!-- <el-submenu index="5">
                    <template slot="title">托运常识</template>
                    <el-menu-item index="">托运须知</el-menu-item>
                    <el-menu-item index="">托运新闻</el-menu-item>
                </el-submenu> -->
                    <el-menu-item index="/Know">托运常识</el-menu-item>
                    <!-- <el-submenu index="6">
                    <template slot="title">关于我们</template>
                    <el-menu-item index="">公司介绍</el-menu-item>
                    <el-menu-item index="">公司资质</el-menu-item>
                    <el-menu-item index="">联系我们</el-menu-item>
                    <el-menu-item index="">在线留言</el-menu-item>
                </el-submenu> -->
                    <el-menu-item index="/down">APP下载</el-menu-item>
                    <el-menu-item> <a target="_blank" href="https://www.douyin.com/user/MS4wLjABAAAAG-FXI3hUegc7A7ZTg8Gns4ZSoH3DBc9lEb54C8KdaO_759WbcIc7QLehHCGV0R-h"><img src="@/assets/tiktok.png" /> 抖音</a></el-menu-item>
                    <el-menu-item class="phone" index="">
                        全国服务热线 <img src="@/assets/phone_logo.png" />
						：400-8080-525</el-menu-item>
                </el-menu>
            </div>
        </div>

        <div class="suspension">
            <div @click="service">
                <img src="@/assets/suspension/person.png" />
                <span>在线咨询</span>
            </div>
            <div class="telephone">
                <div class="T-phone">
                    <img src="@/assets/suspension/The_phone.png" />
                    <span>服务热线</span>
                </div>
                <img src="@/assets/suspension/phone.png" />
                <span>服务热线</span>
            </div>
            <div class="service1">
                <div class="show1">
                    <img src="@/assets/service_code.png" />
                    <span>微信客服</span>
                </div>
                <img src="@/assets/suspension/wx.png" />
                <span>微信客服</span>
            </div>
            <div class="service2">
                <div class="out_show2">
                    <div class="show2">
                        <img src="@/assets/Android.jpg" />
                        <span>Android下载</span>
                    </div>
                    <div class="show2">
                        <img src="@/assets/Apple_app.png" />
                        <span>Ios下载</span>
                    </div>
                </div>
                <img src="@/assets/suspension/app.png" />
                <span>app下载</span>
            </div>
            <div class="service3">
                <div class="show3">
                    <img src="@/assets/app_code.png" />
                    <span>在线下单</span>
                </div>
                <img src="@/assets/suspension/down.png" />
                <span>在线下单</span>
            </div>
        </div>

        <div class="chat" :style="{ display: show }">
            <iframe
                width="400"
                height="500"
                frameborder="0"
                src="https://ykf-webchat.7moor.com/wapchat.html?accessId=f12f13c0-0c2d-11ee-b307-5fc5da2ad7a4&fromUrl=%E5%AE%98%E7%BD%91&urlTitle=1&language=ZHCN&otherParams={%22peerId%22:%2210060368%22}"
            >
            </iframe>
        </div>

        <router-view></router-view>

        <Copyright></Copyright>
    </div>
</template>

<script>
import Copyright from '@/components/copyright.vue'

export default {
    components: {
        Copyright
    },
    data() {
        return {
            activeIndex: '/Home',
            show_s: 0
        }
    },
    methods: {
        logon() {
            console.log('注册')
        },
        Sign() {
            console.log('登录')
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath)
        },
        service() {
            this.show_s++
        }
    },
    computed: {
        show() {
            return this.show_s % 2 === 0 ? 'none' : 'block'
        }
    }
}
</script>

<style lang="less" scoped>
.box {
    width: 100%;
    height: 50vh;
    .nav_top {
        width: 100%;
        height: 4.6vh;
        background-color: #eeeeee;
        display: flex;
        justify-content: center;
        .nav_top_cont {
            width: 70%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #33334b;
            font-size: 0.9rem;
            span {
                margin-right: 3vh;
                cursor: pointer;
            }
            span:hover {
                color: #337ab7;
            }
        }
    }
    .nav_logo {
        width: 100%;
        height: 12vh;
        display: flex;
        justify-content: center;
        .nav_logo_cont {
            width: 85%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .div_logo {
                width: 22vh;
                img {
                    width: 100%;
                }
            }
        }
    }

    .suspension {
        position: fixed;
        bottom: 25vh;
        right: 10px;
        background-color: #fff;
        border-radius: 10px;
        z-index: 999;
        > div {
            width: 10vh;
            height: 10vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 700;
            border-bottom: 1px solid #dddddd;
            img {
                width: 4vh;
            }
            span {
                margin-top: 0.6vh;
                font-size: 0.8rem;
            }
        }
        > div:last-child {
            border-bottom: none;
        }
        .telephone {
            position: relative;
            .T-phone {
                width: 18vh;
                background-color: #fff;
                border-radius: 6px;
                position: absolute;
                left: -20vh;
                text-align: center;
                padding: 0.4vh;
                display: none;
                img {
                    width: 100%;
                }
                span {
                    font-size: 1rem;
                }
            }
        }
        .telephone:hover {
            .T-phone {
                display: block;
            }
        }
        .service1 {
            position: relative;
            .show1 {
                width: 18vh;
                background-color: #fff;
                border-radius: 6px;
                position: absolute;
                left: -20vh;
                text-align: center;
                padding: 0.4vh;
                display: none;
                img {
                    width: 100%;
                }
                span {
                    font-size: 1rem;
                }
            }
        }
        .service1:hover {
            .show1 {
                display: block;
            }
        }
        .service2 {
            position: relative;
            .out_show2 {
                display: flex;
                position: absolute;
                left: -40vh;
                display: none;
                .show2 {
                    width: 18vh;
                    background-color: #fff;
                    border-radius: 6px;
                    text-align: center;
                    padding: 0.4vh;
                    margin-left: 1vh;
                    img {
                        width: 100%;
                    }
                    span {
                        font-size: 1rem;
                    }
                }
            }
        }
        .service2:hover {
            .out_show2 {
                display: flex;
            }
        }
        .service3 {
            position: relative;
            .show3 {
                width: 18vh;
                background-color: #fff;
                border-radius: 6px;
                position: absolute;
                left: -20vh;
                text-align: center;
                padding: 0.4vh;
                display: none;
                img {
                    width: 100%;
                }
                span {
                    font-size: 1rem;
                }
            }
        }
        .service3:hover {
            .show3 {
                display: block;
            }
        }
    }
    .chat {
        position: fixed;
        bottom: 0;
        right: 13vh;
        z-index: 1000;
    }
}
</style>