var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"nav_logo"},[_c('div',{staticClass:"nav_logo_cont"},[_vm._m(1),_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"text-color":"#333","active-text-color":"#E36012","mode":"horizontal","router":""},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"/Home"}},[_vm._v("首页")]),_c('el-submenu',{attrs:{"index":"2"}},[_c('template',{slot:"title"},[_vm._v("花仙运车服务")]),_c('el-menu-item',{attrs:{"index":"/Flow"}},[_vm._v("托运流程")]),_c('el-menu-item',{attrs:{"index":"/Subject"}},[_vm._v("专业运输合同")])],2),_c('el-menu-item',{attrs:{"index":"/Shipment"}},[_vm._v("托运线路")]),_c('el-menu-item',{attrs:{"index":"/Know"}},[_vm._v("托运常识")]),_c('el-menu-item',{attrs:{"index":"/down"}},[_vm._v("APP下载")]),_c('el-menu-item',[_c('a',{attrs:{"target":"_blank","href":"https://www.douyin.com/user/MS4wLjABAAAAG-FXI3hUegc7A7ZTg8Gns4ZSoH3DBc9lEb54C8KdaO_759WbcIc7QLehHCGV0R-h"}},[_c('img',{attrs:{"src":require("@/assets/tiktok.png")}}),_vm._v(" 抖音")])]),_c('el-menu-item',{staticClass:"phone",attrs:{"index":""}},[_vm._v(" 全国服务热线 "),_c('img',{attrs:{"src":require("@/assets/phone_logo.png")}}),_vm._v(" ：400-8080-525")])],1)],1)]),_c('div',{staticClass:"suspension"},[_c('div',{on:{"click":_vm.service}},[_c('img',{attrs:{"src":require("@/assets/suspension/person.png")}}),_c('span',[_vm._v("在线咨询")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"chat",style:({ display: _vm.show })},[_c('iframe',{attrs:{"width":"400","height":"500","frameborder":"0","src":"https://ykf-webchat.7moor.com/wapchat.html?accessId=f12f13c0-0c2d-11ee-b307-5fc5da2ad7a4&fromUrl=%E5%AE%98%E7%BD%91&urlTitle=1&language=ZHCN&otherParams={%22peerId%22:%2210060368%22}"}})]),_c('router-view'),_c('Copyright')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav_top"},[_c('div',{staticClass:"nav_top_cont"},[_c('div',[_vm._v("深圳市花仙科技有限公司")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div_logo"},[_c('img',{attrs:{"src":require("@/assets/logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"telephone"},[_c('div',{staticClass:"T-phone"},[_c('img',{attrs:{"src":require("@/assets/suspension/The_phone.png")}}),_c('span',[_vm._v("服务热线")])]),_c('img',{attrs:{"src":require("@/assets/suspension/phone.png")}}),_c('span',[_vm._v("服务热线")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service1"},[_c('div',{staticClass:"show1"},[_c('img',{attrs:{"src":require("@/assets/service_code.png")}}),_c('span',[_vm._v("微信客服")])]),_c('img',{attrs:{"src":require("@/assets/suspension/wx.png")}}),_c('span',[_vm._v("微信客服")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service2"},[_c('div',{staticClass:"out_show2"},[_c('div',{staticClass:"show2"},[_c('img',{attrs:{"src":require("@/assets/Android.jpg")}}),_c('span',[_vm._v("Android下载")])]),_c('div',{staticClass:"show2"},[_c('img',{attrs:{"src":require("@/assets/Apple_app.png")}}),_c('span',[_vm._v("Ios下载")])])]),_c('img',{attrs:{"src":require("@/assets/suspension/app.png")}}),_c('span',[_vm._v("app下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service3"},[_c('div',{staticClass:"show3"},[_c('img',{attrs:{"src":require("@/assets/app_code.png")}}),_c('span',[_vm._v("在线下单")])]),_c('img',{attrs:{"src":require("@/assets/suspension/down.png")}}),_c('span',[_vm._v("在线下单")])])
}]

export { render, staticRenderFns }