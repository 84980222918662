<template>
    <div class="app_index">
        <!-- 头部logo/电话 -->
        <div class="head_logo">
            <div class="left_img">
                <img src="../../assets/logo.png" />
            </div>
            <div class="right_phone">热线 : 400-8080-525</div>
        </div>

        <!-- 头部logo/电话--盒子覆盖顶层 -->
        <div class="head_logo_copy"></div>

        <!-- 图片轮播 -->
        <div class="swit">
            <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <img :src="image.url" />
                </van-swipe-item>
            </van-swipe>
        </div>

        <!-- 跳转_宫格 -->
        <div class="to_three">
            <div class="to_li" @click="to(0)">
                <div class="li_logo">
                    <img src="../../assets/app_line.png" />
                </div>
                <div>托运线路</div>
            </div>
            <div class="to_li" @click="to(1)">
                <div class="li_logo">
                    <img src="../../assets/app_sense.png" />
                </div>
                <div>托运常识</div>
            </div>
            <div class="to_li" @click="to(2)">
                <div class="li_logo">
                    <img src="../../assets/app_phone.png" />
                </div>
                <div>联系电话</div>
            </div>
            <div class="to_li" @click="to(3)">
                <div class="li_logo">
                    <img src="../../assets/app_app.png" />
                </div>
                <div>App下载</div>
            </div>
        </div>

        <!-- 报价 -->
        <div class="quotation">
            <div class="top_title">
                <div class="line"></div>
                &nbsp;&nbsp;
                <div class="text">超过<span>6000</span>位客户选择我们</div>
                &nbsp;&nbsp;
                <div class="line"></div>
            </div>

            <div class="center_input">
                <van-field
                    v-model="value_one"
                    is-link
                    readonly
                    label="车型"
                    placeholder="请选择车类型"
                    @click="flag.show1 = true"
                />
                <van-popup v-model="flag.show1" round position="bottom">
                    <van-cascader
                        title="请选择车类型"
                        :options="car_options"
                        @close="flag.show1 = false"
                        @finish="onFinish1"
                    />
                </van-popup>

                <van-field
                    v-model="value_two"
                    is-link
                    readonly
                    label="出发地"
                    placeholder="请选择出发地"
                    @click="flag.show2 = true"
                />
                <van-popup v-model="flag.show2" round position="bottom">
                    <van-cascader
                        title="请选择出发地"
                        :options="options"
                        @close="flag.show2 = false"
                        @finish="onFinish2"
                    />
                </van-popup>

                <van-field
                    v-model="value_three"
                    is-link
                    readonly
                    label="目的地"
                    placeholder="请选择目的地"
                    @click="flag.show3 = true"
                />
                <van-popup v-model="flag.show3" round position="bottom">
                    <van-cascader
                        title="请选择目的地"
                        :options="options"
                        @close="flag.show3 = false"
                        @finish="onFinish3"
                    />
                </van-popup>

                <!-- 输入手机号，调起手机号键盘 -->
                <van-field
                    v-model="phone_input_top"
                    type="tel"
                    label="手机号"
                    placeholder="请输入手机号"
                />

                <div style="margin: 16px" @click="Submit()">
                    <van-button
                        round
                        block
                        type="info"
                        native-type="submit"
                        :disabled="isFormReady()"
                        >15秒获取报价</van-button
                    >
                </div>
            </div>
        </div>

        <!-- 服务 -->
        <div class="service">
            <div class="one">花仙运车品牌<span>服务中心</span></div>
            <div class="two">SERVICE CENTER</div>

            <!-- 图片轮播 -->
            <div class="swit">
                <van-swipe :autoplay="3000" height="210">
                    <van-swipe-item
                        v-for="(image, index) in images_two"
                        :key="index"
                    >
                        <img :src="image.url" />
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>

        <!-- 服务优势 -->
        <div class="service_two">
            <div class="one">花仙运车品牌<span>服务优势</span></div>
            <div class="two">SERVICE STEPS</div>

            <!-- 图片轮播 -->
            <div class="swit">
                <van-swipe :autoplay="3000">
                    <van-swipe-item
                        v-for="(image, index) in images_three"
                        :key="index"
                    >
                        <img :src="image.url" />
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>

        <!-- 合同及保险 -->
        <div class="service_three">
            <div class="one">花仙运车品牌<span>合同及保险</span></div>
            <div class="two">OUR SERVICES</div>

            <!-- 图片 -->
            <div class="swit">
                <img src="@/assets/three_li.png" />
            </div>
        </div>

        <!-- 托运流程 -->
        <div class="service_path">
            <div class="one">花仙运车品牌<span>托运流程</span></div>
            <div class="two">PROCEDURE</div>

            <!-- 图片 -->
            <div class="swit">
                <img src="@/assets/phone_introduce.png" />
            </div>
        </div>

        <!-- 公司介绍 -->
        <div class="service_video">
            <div class="one">花仙运车<span>品牌简介</span></div>
            <div class="two">ABOUT US</div>

            <!-- 视频 -->
            <div class="swit">
                <video
                    width="100%"
                    src="https://yuncheda.oss-cn-shenzhen.aliyuncs.com/app/promotionalFilm/promotionalFilm.mp4"
                    controls
                ></video>
            </div>

            <div class="text_introduce">
                花仙运车，中国领先的轿车托运服务企业，国家商标注册专业运车品牌，花仙运车已在全国一二线城市设立办事处，三四线城市均有合作服务网点。配备三百多辆标准商品运输车，可随时调配几百辆合作运输车辆，服务全国上千家四S店拥有全国一二线城市专线，线路辐射全国，年运输量超过三十万辆。花仙运车始终将轿车安全问题放在首位，提供全程防线服务，为您的爱车保驾护航。在运输中，我们采用升降机及四轮定位固定技术，保证车辆免受擦碰，确保运输轿车安全。我们从提供咨询、APP下载、安装、在线下单，上门取车，车辆检查、合同签订、保险购买、带图查询、托运跟踪及到达验收。提供一对一管家式服务，让您安心、放心、省心和贴心，汽车托运就找花仙运车，让您的爱车安全到达。
            </div>
        </div>

        <!-- 合作伙伴 -->
        <div class="cooperate">
            <div class="one">花仙运车品牌<span>合作伙伴</span></div>
            <div class="two">PARTNER</div>

            <!-- 图片 -->
            <div class="swit">
                <img src="@/assets/pingpai.png" />
            </div>
        </div>

        <!-- 二维码 -->
        <qrcode></qrcode>

        <div class="suspension" @click="to_serve">
            <img src="@/assets/suspension/person.png" />
            <span>在线咨询</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { slect_one, Options } from '@/util/app_area'
import { Notify } from 'vant'
import qrcode from './to/component/qrcode'

export default {
    data() {
        return {
            images: [
                { id: 2, url: require('@/assets/r2.jpg') },
                { id: 3, url: require('@/assets/r3.jpg') },
                { id: 4, url: require('@/assets/r4.jpg') }
            ],
            images_two: [
                { url: require('@/assets/ser1.jpg') },
                { url: require('@/assets/ser2.jpg') },
                { url: require('@/assets/ser3.jpg') },
                { url: require('@/assets/ser4.jpg') }
            ],
            images_three: [
                { url: require('@/assets/a_introduce1.png') },
                { url: require('@/assets/a_introduce2.png') },
                { url: require('@/assets/a_introduce3.png') },
                { url: require('@/assets/a_introduce4.png') }
            ],
            car_options: slect_one, // 车型
            options: Options, // 城市
            flag: {
                show1: false,
                show2: false,
                show3: false
            },

            value_one: '轿车', //默认轿车
            value_two: '', //始发
            value_three: '', //到达
            phone_input_top: '' //电话
        }
    },
    components: {
        qrcode: qrcode
    },
    methods: {
        to(num) {
            switch (num) {
                case 0:
                    this.$router.push('/App_line')
                    break
                case 1:
                    this.$router.push('/App_sense')
                    break
                case 2:
                    this.$router.push('/App_phone')
                    break
                case 3:
                    this.$router.push('/App_app')
                    break
            }
        },

        onFinish1({ selectedOptions }) {
            this.flag.show1 = false
            this.value_one = selectedOptions
                .map((option) => option.text)
                .join('/')
            // console.log(this.value_one);
        },
        onFinish2({ selectedOptions }) {
            this.flag.show2 = false
            this.value_two = selectedOptions
                .map((option) => option.text)
                .join('/')
            // console.log(this.value_two);
        },
        onFinish3({ selectedOptions }) {
            this.flag.show3 = false
            this.value_three = selectedOptions
                .map((option) => option.text)
                .join('/')
            // console.log(this.value_three);
        },
        isFormReady() {
            if (
                this.value_one != '' &&
                this.value_two != '' &&
                this.value_three != '' &&
                this.phone_input_top != ''
            ) {
                return false
            } else {
                return true
            }
        },
        Submit() {
            const data = {
                carBrand: this.value_one,
                originating: this.value_two,
                destination: this.value_three,
				type: 4,
                phone: this.phone_input_top
            }
            console.log(data)
            const that = this
            axios({
                url: 'https://app.szyuncheda.com/app/memberEnquiry/add', //此处是你向后端发送数据的地址，根据你自己的地址进行修改
                data: data, //此处是要传递的json数据，根据你的参数进行修改
                method: 'POST', //设置为POST请求
                header: {
                    'Content-Type': 'application/json' //规定传递的参数格式为json
                }
            }).then((res) => {
                let The_message = res.data.message
                console.log(The_message)
                if (res.data.code === 200) {
                    Notify({ type: 'success', message: The_message })
                    that.value_one = '轿车' //默认轿车
                    that.value_two = '' //始发
                    that.value_three = '' //到达
                    that.phone_input_top = '' //电话
                } else {
                    Notify({ type: 'danger', message:The_message })
                }
            })
        },
        to_serve() {
            window.open(
                'https://ykf-webchat.7moor.com/wapchat.html?accessId=f12f13c0-0c2d-11ee-b307-5fc5da2ad7a4&fromUrl=官网&urlTitle=1&language=ZHCN&otherParams={%22peerId%22:%2210060368%22}',
                '_blank'
            )
        }
    }
}
</script>

<style lang="less" scoped>
.app_index {
    width: 100%;
    height: auto;
    .head_logo {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        background-color: #fff;
        z-index: 100;
        .left_img {
            width: 40%;
            height: auto;
            img {
                width: 100%;
            }
        }
        .right_phone {
            color: #e36040;
            font-weight: bold;
            font-size: 2.4vh;
            margin-right: 1vh;
        }
    }

    .head_logo_copy {
        width: 100%;
        height: 8vh;
    }

    .swit {
        width: 100%;
        height: auto;
        .van-swipe-item {
            width: 100%;
            height: auto;
            img {
                width: 100%;
                height: 190px;
            }
        }
    }

    .to_three {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        margin-top: 1vh;
        margin-bottom: 1vh;
        color: #fff;
        .to_li {
            width: 23%;
            height: 7vh;
            border-radius: 1vh;
            background-color: #b0e0e2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .li_logo {
                width: 3vh;
                img {
                    width: 100%;
                }
            }
        }
    }

    .quotation {
        width: 100%;
        height: auto;
        margin-top: 3vh;
        .top_title {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #5c5c5c;
            margin-bottom: 2vh;
            .line {
                width: 5vh;
                border-bottom: 1px solid #cccccc;
            }
            .text {
                font-size: 2vh;
                span {
                    color: #e36040;
                }
            }
        }
        .center_input {
        }
    }

    .service {
        width: 100%;
        height: auto; //
        margin-bottom: 1vh;
        .one {
            text-align: center;
            font-size: 1.4rem;
            margin-bottom: 1vh;
            span {
                font-weight: bold;
                color: #e36040;
            }
        }
        .two {
            text-align: center;
            color: #666666;
            font-size: 1.2rem;
            border-bottom: 1px solid #cccccc;
            margin-bottom: 1vh;
        }

        .swit {
            width: 100%;
            height: auto;
            .van-swipe-item {
                width: 100%;
                height: 20vh;
                img {
                    width: 100%;
                    height: 210px;
                }
            }
        }
    }

    .service_two {
        width: 100%;
        height: auto; //
        margin-bottom: 1vh;
        .one {
            text-align: center;
            font-size: 1.4rem;
            margin-bottom: 1vh;
            span {
                font-weight: bold;
                color: #e36040;
            }
        }
        .two {
            text-align: center;
            color: #666666;
            font-size: 1.2rem;
            border-bottom: 1px solid #cccccc;
            margin-bottom: 1vh;
        }

        .swit {
            width: 100%;
            height: auto;
            .van-swipe-item {
                width: 100%;
                height: 24vh;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .service_three {
        width: 100%;
        height: auto; //
        // margin-bottom: 1vh;
        .one {
            text-align: center;
            font-size: 1.4rem;
            margin-bottom: 1vh;
            span {
                font-weight: bold;
                color: #e36040;
            }
        }
        .two {
            text-align: center;
            color: #666666;
            font-size: 1.2rem;
            border-bottom: 1px solid #cccccc;
            margin-bottom: 1vh;
        }

        .swit {
            width: 100%;
            height: 20vh;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .service_path {
        width: 100%;
        height: auto; //
        padding-bottom: 1vh;
        background-color: #f7f7f7;
        padding-top: 1vh;
        .one {
            text-align: center;
            font-size: 1.4rem;
            margin-bottom: 1vh;
            span {
                font-weight: bold;
                color: #e36040;
            }
        }
        .two {
            text-align: center;
            color: #666666;
            font-size: 1.2rem;
            border-bottom: 1px solid #cccccc;
            margin-bottom: 1vh;
        }

        .swit {
            width: 100%;
            // height: 20vh;
            img {
                width: 100%;
                // height: 100%;
            }
        }
    }

    .service_video {
        width: 100%;
        height: auto; //
        padding-bottom: 1vh;
        background-color: #f7f7f7;
        padding-top: 1vh;
        .one {
            text-align: center;
            font-size: 1.4rem;
            margin-bottom: 1vh;
            span {
                font-weight: bold;
                color: #e36040;
            }
        }
        .two {
            text-align: center;
            color: #666666;
            font-size: 1.2rem;
            border-bottom: 1px solid #cccccc;
            margin-bottom: 1vh;
        }

        .swit {
            width: 94%;
            margin: 0 auto;
            // height: 20vh;
        }
        .text_introduce {
            width: 94%;
            margin: 0 auto;
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
    }

    .cooperate {
        width: 100%;
        height: auto; //
        // margin-bottom: 1vh;
        padding-top: 1.4vh;
        padding-bottom: 1vh;
        .one {
            text-align: center;
            font-size: 1.4rem;
            margin-bottom: 1vh;
            span {
                font-weight: bold;
                color: #e36040;
            }
        }
        .two {
            text-align: center;
            color: #666666;
            font-size: 1.2rem;
            border-bottom: 1px solid #cccccc;
            margin-bottom: 1vh;
        }

        .swit {
            width: 100%;
            height: auto;
            img {
                width: 100%;
            }
        }
    }

    .suspension {
        width: 8vh;
        height: 8vh;
        border-radius: 10px;
        position: fixed;
        bottom: 1vh;
        right: 1vh;
        background-color: #fff;
        z-index: 800;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        img {
            width: 5vh;
        }
    }
}
</style>